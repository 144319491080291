<template>
    <form-view 
        v-slot="{ model, onSuccess, onBack }"
        title="Редактирование аккаунта"
        update
        :url-update="updateUrl"
        :url-back="backUrl"
        :action-one="`user/one`"
        :fields="fields"
    >
        <user-form :model="model" @success="onSuccess" @back="onBack" />
    </form-view>
</template>
<script>
import FormView from '@/components/crud/FormView.vue'
import UserForm from '@/components/forms/UserForm.vue'
export default {
    name: 'UserUpdate',
    components: { FormView, UserForm },
    computed: {
        fields () {
            return [ 'id', 'active', 'group_id', 'name', 'role', 'login', 'subjects', 'groups', 'gender' ]
        },
        queryRole () {
            return this.$route.query.role === 'undefined' ? null : this.$route.query.role
        },
        backUrl () {
            const baseUrl = '/user'
            return this.queryRole ? `${baseUrl}?role=${this.queryRole}` : baseUrl
        },
        updateUrl () {
            const baseUrl = '/user/update/:id'
            return this.queryRole ? `${baseUrl}?role=${this.queryRole}` : baseUrl
        }
    }
}
</script>